// Import Splide.js library
import { Splide } from "@splidejs/splide";

// Splide web component
class HeroSlider extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
      new Splide(this, {
        perPage: 1,
        arrows: false,
        focus  : 0,
        classes: {
          page      : 'splide__pagination__page bg-white',
        },
        breakpoints: {
          1024: {
            type: 'loop',
            perPage: 1,
            paginationDirection: 'ltr',
          },
        }
      }).mount();
    }
  }

customElements.define('hero-slider', HeroSlider);